export type ScreeningService = 'healix' | 'cega' | 'verisk';
export type GroupType = 'individual' | 'group' | 'couple' | 'family';
export type ActivitiesLevel = 'level1' | 'level2' | 'level3' | 'level4';
export type TravellerType = 'adult' | 'child';
export type SchemeType = 'Single Trip' | 'Annual Multi Trip' | 'Longstay';
export type TripType = 'single' | 'annual' | 'longstay';
export type ChannelType = 'AGG' | 'B2C';
export type RenewalStatus = 'auto' | 'manual' | 'lapse' | 'refer';

export const ADMIRAL_LEISURE_TRAVEL = 'Leisure Travel'
export const ADMIRAL_LEISURE_TRAVEL_RENEWAL = 'Leisure Travel Renewal'
export const ADMIRAL_LUCKY_TRIP_TRAVEL = 'Lucky Trip'

export interface IApplicationOptions {
  calculate?: boolean;
  patch?: boolean;
}

export interface IAddress {
  line1: string;
  line2?: string;
  line3?: string;
  town?: string;
  county?: string;
  country?: string;
  postcode: string;
}

export interface ITraveller {
  id?: string;
  title: string;
  firstName: string;
  lastName: string;
  phone?: string;
  email?: string;
  dob?: string;
  age?: number;
  address?: IAddress;
  screening?: {
    meta: any;
  };
  metadata?: any;
  screeningService?: ScreeningService;
  screeningSessionId?: string;
  type: TravellerType;
  marketingOptions: {
    email: number,
    phone: number,
    post: number,
    sms: number,
  };
}

export interface ICustomer {
  id: string;
  title: string;
  firstName: string;
  lastName: string;
  companyName?: string;
  customerName: string;
  password?: string;
  passwordConfirmation?: string;
  dob?: string;
  address?: IAddress;
  email?: string;
  phone1?: string;
  phone2?: string;
  deceased: boolean;
  status: string;
  metadata: any;
  policies: IPolicy[];
  activePolicies: IPolicy[];
  expiredPolicies: IPolicy[];
  policyApplications: IPolicyApplication[];
  renewals: IPolicyApplication[];
  marketingOptions: {
    email: number,
    phone: number,
    post: number,
    sms: number,
  };
  claimsIframeUrl?: string;
  claims?: ICustomerClaim[];
  loadClaims?: boolean;
  upsellMarketing?: any[];
  paymentMethods?: ICustomerPaymentMethod[];
}

export interface IOffer {
  household: { deeplink: string, premium: number };
}
export interface ICustomerPaymentMethod {
  id: string;
  status: string;
  expiry: string;
  assigned: boolean;
  permissions?: {
    consent?: boolean,
    intent?: boolean,
    recurring_payments?: boolean,
  };
  maskedPan?: string,
}

export interface ICustomerClaimClient {
  emailAddress: string;
  title: string;
  firstName: string;
  lastName: string;
  homeTelephoneNumber: string;
  mobilePhoneNumber: string;
  isContact: boolean;
  address: {
    address1: string;
    address2: string;
    address3: string;
    address4: string;
    address5: string;
    postcode: string;
  };
}

export interface ICustomerClaimSection {
  name: string;
  limit: number;
  excess: number;
  status: string;
  dateClosed: string;
  dateOpened: string;
}

export interface ICustomerClaim {
  claimNumber: string;
  clients: ICustomerClaimClient[];
  policyNumber: string;
  tripStartDate: string;
  tripEndDate: string;
  tripBookingDate: string;
  incidentDate: string;
  dateCreated: string;
  dateClosed: string;
  statusId: string;
  sections: ICustomerClaimSection[];
}

export interface IPolicyClaims {
  type: string;
  id: string;
  attributes: IPolicyClaimsAttributes;
}

export interface IPolicyClaimsAttributes {
  admin: string;
  claim_id: string;
  claim_status: string;
  is_rateable: boolean;
  policy_id: string;
}

export interface IOptionMap {
  [optionKey: string]: string | boolean;
}

export interface IDeclaration {
  type: string;
  content: string;
  answer: boolean | string;
  question_type: string;
  next_key: string;
  true_key: string;
  false_key: string;
  display: boolean;
  help_text: string;
  negotiable: boolean;
  should_not_screen: boolean;
}

export interface IActivities {
  name: string;
}

export interface IDestination {
  id: string;
  name: string;
  included: boolean;
}

export interface IMarketingUpsell {
  policyId: string;
  policyApplicationId: string;
  metadata: boolean;
  updatedAt: boolean;
}

export interface IStateCities {
  id: string;
  name: string;
  included: boolean;
  countryCode: string;
}

export interface IOccupation {
  id: string;
  name: string;
}

export interface IIndustry {
  id: string;
  name: string;
}

export interface IPolicy {
  policyNumber: string;
  insured: string;
  status: string;
  id: string;
  version: number;
  productName: string;
  userName: string;
  brokerName: string;
  premium?: number;
  channelName: string;
  minimumDocumentCount: number;
  isInsideCancellationPeriod: boolean;
  metadata: any;
  commencesAt: string;
  expiresAt: string;
  issuedAt: string;
  cancelledAt: string;
  documents: IDocument[];
  renewalStatus: RenewalStatus;
  totalPremiumValue: number;
  premiums: IPremium[];
  travellers: ITraveller[];
  effective_date?: any;
  mta?: boolean;
  scheme_id?: string;
  activePaymentMethod?: IPaymentMethod;
  assignedPaymentMethods?: IPaymentMethod;
  hasRenewal?: boolean;
  termNumber?: number;
}

export interface IPolicyApplication {
  id?: string;
  tripType: TripType;
  quoteType: string;
  renewingPolicyNumber: string;
  renewingPolicyId: string;
  renewalStatus?: string;
  nextTermRenewalStatus?: string;
  groupType: GroupType;
  quotationTypes: string;
  residency: string;
  destinations: string[];
  stateCities?: string[];
  occupations: string[];
  industries: string[];
  region: string;
  occupation?: IOccupation;
  industry?: IIndustry;
  claims?: boolean;
  criminal?: boolean;
  startDate: string;
  endDate: string;
  maxTripDuration?: number;
  travellers: ITraveller[];
  quotationDeclaration: boolean;
  options?: IOptionMap;
  medicalDeclaration?: IDeclaration[];
  activitiesLevel?: ActivitiesLevel;
  searchActivities?: IActivities[];
  // winterSports?: boolean;
  // gadgetCover?: boolean;
  // golfCover?: boolean;
  // hazardousActivities?: boolean;
  // businessEquipment?: boolean;
  // cruiseCover?: boolean;
  quotes?: IQuotes;
  schemeId?: string;
  calculateAllSchemes?: boolean;
  sendSms?: boolean;
  errors?: any[];
  purchaseDeclaration?: boolean;
  paymentToken?: string;
  payOnAccount: boolean;
  steps: string[];
  password: string;
  passwordConfirmation: string;
  customer: ICustomer;
  quotePremiums: IPremium[];
  premiums: IPremium[];
  quoteReference?: string;
  policyDeclaration: boolean;
  insured: string;
  status: string;
  documents: IDocument[];
  generateDocuments: boolean;
  emailSend: boolean;
  channel?: IChannel;
  campaignCode?: string;
  campaign?: string;
  payment?: IPaymentInfo;
  createAccount: boolean;
  brokerId: string;
  communications: {
    post: boolean,
  };
  preventPatch: boolean;
  documentPack: string;
  salesChannel?: string;
  additionalRenewalLetter?: string;
  purchasedFromB2C?: boolean;
  portal?: boolean;
  purchaseChannel?: string;
  // Capping factor
  cappingFactor?: number;
  priceIncrease?: number;
  cappingFactorBreakdownKey?: string;
  channelName?: string;
  fraudCheck?: IFraudCheckResponse;
  createdAt?: string;
  updatedAt?: string;
  upSellIncreaseRatio: {
    admiral?: number;
    gold?: number;
    platinum?: number;
  };
  cprResponse?: any;
  createdBeforeFixedSchemeVersionRule?: boolean;
  medicalCoverForCovid?: boolean;
  claimCalculated?: {
    perilName: string,
    claimCount: number,
    claimRate: number,
  };
  reuseCustomerAddress?: boolean;
  billing?: IBilling;
  signposted?: ISignposted;
  activePaymentMethod?: IPaymentMethod;
  assignedPaymentMethods?: IPaymentMethod;
  permissions?: boolean;
  renewalStatusChange?: string;
  upsellDate?: string;
  timeOfQuote?: string;
  mods?: any[];
  newBusinessLagDays?: number;
  renewalTerm?: number;
  version?: number;
  renewal?: boolean;
  errorMessage?: string;
  waitingListExclusion?: boolean;
  waitingListExclusionNote?: string;
  previousStartDate?: string;
  previousQuoteDate?: string;
}

export interface IBilling {
  title: string;
  firstName: string;
  lastName: string;
  address: {
    line1: string;
    line2?: string;
    town: string;
    county: string;
    postcode: string;
    country: string;
  };
}

export interface ISignposted {
  medicalDecline: boolean;
  medicalLoad: boolean;
}

export interface IFraudCheckResponse {
  answer?: any;
  errorId?: string;
  response?: any;
}

export interface IFraudCheckRequest {
  id?: string;
  blackbox?: string;
}

export interface IPaymentInfo {
  cardNumber: string;
  cardName: string;
  cardExpiry: string;
  cardCvc: string;
}

export interface IProduct {
  id: string;
  name: string;
  productType: string;
  productCode: string;
  status: string;
  summary: string;
  description: string;
  icon: string;
  metadata: any;
  documents: {
    [name: string]: string;
  };
}

export interface IQuotes {
  [levelType: string]: {
    [tripType: string]: IPremium;
  };
}

export interface IScheme {
  id: string;
  coverLevel: string;
  status: string;
  logo: string;
  name: string;
  insurer: string;
  documents: {
    [name: string]: string;
  };
  includedOptions: object;
  allBenefits: IBenefit[];
}

export interface IBenefit {
  name: string;
  text: string;
  inverse: boolean;
  excess: string;
  displayName: string;
  shortName: string;
  description: any;
  subBenefits?: ISubBenefit[];
  deprecation?: any;
}

export interface ISubBenefit {
  name: string;
  text: string;
  excess: string;
  description?: string;
}

export interface IInformation {
  standardExcess: string;
  hasGroups: boolean;
  boltOns: {
    [key: string]: any;
  };
  upgrade: {
    schemeId: string;
    coverLevel: string;
    amount: {
      total: number;
      difference: number;
    };
  };
  allBenefits?: IBenefit[];
  maxTripDuration?: number;
  medicalCoverForCovid?: boolean;
  preSignpostScheme?: boolean;
  claimCalculated?: {
    claimCount?: number,
    claimRate?: number,
    perilName?: string,
  };
  startDate?: string;
  region?: string;
  tripType?: string;
  fraudCheck?: boolean;
  showRewardsBanner?: boolean;
  upSellIncreaseRatio?: number;
  upsellMaxTripDuration?: number;
  cprResponse?: any;
  timeOfQuote?: string;
  mods?: any[];
  controlGroup?: number;
}

export interface IPremium {
  id: string;
  schemeType: SchemeType;
  calculatedAt: string;
  validUntil: string;
  currency: string;
  tax: number;
  exTax: number;
  principal: number;
  gross: number;
  discount: number;
  taxRate: number;
  isSignposted?: boolean;
  grossExAdditional: number;
  benefits: IBenefit[];
  scheme: IScheme;
  information: IInformation;
  errors: any;
  declines?: IDeclines[];
  referrals?: IReferrals[];
  premiumBeforeAdjustment?: IPremiumBeforeAdjustment;
  campaigns?: any;
}

export interface IPremiumBeforeAdjustment {
  currency: string;
  principal: number;
  medical: number;
  tax: number;
  exTax: number;
  gross: number;
}

export interface IDeclines {
  name: string;
  reason: string;
}

export interface IReferrals {
  name: string;
  reason: string;
}

export interface IStep {
  title: string;
  key: string;
  route: string;
  saveApplication: boolean;
  saveApplicationWithPatch: boolean;
  calculatePremium: boolean;
  hidden?: boolean;
  parent?: string;
}

export interface IBaseChannel {
  id: string;
  name: string;
  key: string;
}

export interface IBCP {
  enabled: boolean;
  doNotSell: boolean;
  messageKey: string;
  contents: {
    [key: string]: string;
  };
  allowedSchemeTypes?: string[];
}

export interface IChannel extends IBaseChannel {
  // id: string;
  // name: string;
  channelType: ChannelType;
  // key: string;
  whitelabel: boolean;
  products: IProduct[];
  baseName: string;
  phone: string;
  footer: string;
  openingHours: string;
  gtmId: string;
  cseScript: string;
  offlineSchedule?: IOfflineSchedule[];
  steps?: IStep[];
  channelLogo?: string;
  additionalLogo?: string;
  additionalAggregatorOptions: IAdditionalAggregatorOptions[];
  bcp?: IBCP;
  emergencyNumber?: string;
  claimsNumber?: string;
}

export interface IAdditionalAggregatorOptions {
  key: string;
  name: string;
  type: string;
}

export interface IOfflineSchedule {
  type: string;
  message: string;
  redirectTo?: string;
  startAt?: string;
  endAt?: string;
}

export interface IPostcodeLookup {
  id: string;
  line: string;
}

export interface IPostcodeAddress {
  organisation: string;
  line1: string;
  line2: string;
  line3: string;
  town: string;
  county: string;
  postcode: string;
  country: string;
}

export interface IDocument {
  id: string;
  name: string;
  filePath: string;
  shortName: string;
  displayName: string;
  versionNumber?: number;
  status?: string;
  documentType?: string;
  fromDate?: string;
  toDate?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface ITrackingLink {
  id: string;
  name: string;
  phone: string;
  status: string;
}

export interface IPaymentMethod {
  id: string;
  type: string;
}

export interface IMTACancellationOptions {
  too_expensive: IDiscountTypes;
  poor_service: IDiscountTypes;
  other: IDiscountTypes;
  cover_not_suitable: IDiscountTypes;
  discount_to_show_from_start_date_in_days: string,
  not_travelling_soon: IDiscountTypes;
}

export interface IDiscountTypes {
  amt: {
    full_discount: {
      id: string;
      code: string;
      discount: number;
    },
    additional_discount: {
      id: string;
      code: string;
      discount: number;
    }
  },
  renewal: {
    full_discount: {
      id: string;
      code: string;
      discount: number;
    },
    additional_discount: {
      id: string;
      code: string;
      discount: number;
    }
  }
}

export interface ISelectedTravellers {
  waitingList?: string[]
  isTerminal?: string[]
}

export const WAITINGLIST = 'waitingList';
export const ISTERMINAL = 'isTerminal';
