import * as React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { flow, get } from 'lodash';
import { Field, formValueSelector } from 'redux-form';
import FieldFormControl from '../../../../components/FieldFormControl/index';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as quoteActions } from '../../../../services/quote/reducer';
import { IStore } from '../../../../redux/IStore';
import { FaCheck, FaExclamationTriangle } from 'react-icons/fa';
import { IQuoteActions, IQuoteState } from '../../../../services/quote/models';

interface IVoucherCodeProps {
  quote: IQuoteState;
  campaignCode: string;
  dispatch: any;
  change: any;
  applyCallback?: any;
  actions: {
    quote: IQuoteActions;
  };
}

class VoucherCode extends React.Component<IVoucherCodeProps> {
  public componentWillMount() {
    const { quote, actions, dispatch, change } = this.props;
    if (quote.campaignCode) {
      dispatch(change('campaignCode', quote.campaignCode));
      actions.quote.dispatchVoucherCode(quote.campaignCode);
    }
  }

  private checkVoucherCode = () => {
    const { actions, campaignCode, applyCallback } = this.props;

    Promise.resolve(actions.quote.checkVoucherCode(campaignCode)).then((data) => {
      if (applyCallback && get(data, 'id', null)) {
        applyCallback();
      }
    });
  }

  private renderButtonMessage = () => {
    const { quote } = this.props;

    if (quote.voucherCodeStatus === 'success' && quote.regexVoucherCode) {
      return <span>Discount applied <FaCheck/></span>;
    }

    if (quote.voucherCodeStatus === 'success') {
      return <span>{quote.voucherCodeMessage} <FaCheck/></span>;
    }

    if (quote.voucherCodeStatus === 'error') {
      return <span>{quote.voucherCodeMessage} <FaExclamationTriangle/></span>;
    }

    return <span>Apply</span>;
  }

  public render() {
    const { quote } = this.props;

    return (
      <div>
        <div className="section-title">
          <h2>Do you have a voucher code?</h2>
        </div>
        <div className="section-group">
          <div className="section">
            <Row>
              <Col sm={12} className="voucher-code">
                <p className="primary-text">If you have a voucher code or an active Admiral Car, Van, Home or
                  Landlord policy number please enter it below.</p>
                <ul>
                  <li className="list">Adding a policy number will give you 10% off</li>
                  <li className="list">Only one discount can be applied per policy</li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col sm={8} className={quote.voucherCodeStatus === 'error' && 'has-error'}>
                <Field
                  name="campaignCode"
                  className="voucher-code-input"
                  placeholder={'Enter your voucher code or policy number here...'}
                  component={FieldFormControl}
                  disabled={null !== quote.campaignCode || quote.voucherCodeStatus === 'success'}
                  type={quote.voucherCodePreventDisplay ? 'password' : 'text'}
                />
              </Col>
              <Col sm={4}>
                <Button
                  bsStyle={quote.voucherCodeStatus === 'error' ? 'danger' : 'primary'}
                  block={true}
                  onClick={this.checkVoucherCode}
                  disabled={null !== quote.campaignCode}
                >
                  {this.renderButtonMessage()}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

let selector;

export default flow([
  (component) => {
    selector = formValueSelector('quote');

    return component;
  },
  connect(
    (state: IStore) => {
      const campaignCode = selector(state, 'campaignCode');

      return {
        quote: state.quote,
        campaignCode,
      };
    },
    (dispatch) => ({
      actions: {
        quote: bindActionCreators({ ...quoteActions }, dispatch),
      },
    }),
  ),
])(VoucherCode);
