import React = require('react');

export const MedicalInformation = () => {
  return (
    <div>
      <p>This information will be used to assess whether we are able to offer you quote.</p>
      <p><span className={'medical-declaration-text-sub'}>Pre-existing conditions:</span> Any illness, injury or medical condition that someone had at any time before buying insurance.</p>
      <p><span className={'medical-declaration-text-sub'}>Covid-19:</span> If you had a positive diagnosis for Covid-19 and were prescribed medication, received treatment or
        had a consultation with a doctor/hospital specialist in the past two years, this will need to be declared.</p>
      <p><span className={'medical-declaration-text-sub'}>Changes In Health:</span> You must tell us if any insured person is diagnosed with a new medical condition or awaiting
        a new medical diagnosis, or if their health changes (including any changes in medication, being placed on a
        waiting list for tests and/or investigations, treatment or admission to hospital) after you’ve bought the
        policy. Failure to do so could affect any future claims.</p>
      <p><span className={'medical-declaration-text-sub'}>Mental Health Conditions: </span> If you would like information about travel insurance and mental health conditions or
        more support to complete your insurance application, please visit our dedicated page <a
          href={'https://www.admiral.com/magazine/guides/travel/travel-and-mental-health-guide'}
          target="_blank"> here</a>.</p>
    </div>
  )
}
