import * as moment from 'moment';
import { isString } from 'lodash';
import * as luhn from 'luhn';
import * as emojiRegex from 'emoji-regex';

type ValidatorReturn = string | null;
type DateInput = string | moment.Moment;
type PassWordValidatorInput = any[];

const DATE_FORMAT = 'DD/MM/YYYY';

const isRequired = (value): ValidatorReturn => {
  // An exact false is an answered question
  // checking something isn't false should be another validator
  if (value === false) {
    return null;
  }

  return !value || (typeof value.trim === 'function' ? value.trim().length === 0 : value.length === 0) ? 'This field is required.' : null;
};

const mustBeFalse = (value): ValidatorReturn => {
  if (value === false) {
    return null;
  }

  return ' ';

};

const isNotFalse = (value): ValidatorReturn => {
  if (value) {
    return null;
  }

  return 'This field is required.';
};

const parseDate = (date: DateInput): moment.Moment => {
  if (!isString(date)) {
    return date;
  }

  return moment(date, DATE_FORMAT);
};

const isValidDate = (value: string): ValidatorReturn => {
  if (value.length < 10) {
    return 'Invalid format, must be: dd/mm/yyyy';
  }

  return !parseDate(value).isValid() ? 'Invalid Date' : null;
};

const isBefore = (date: DateInput, message: string = null) => (value: string): ValidatorReturn => {
  date = parseDate(date);
  const m = message ? message : 'Must be before ' + date.format(DATE_FORMAT);
  const momentValue = parseDate(value);

  return date.isBefore(momentValue) ? m : null;
};

const isAfter = (date: DateInput, message: string = null) => (value: string): ValidatorReturn => {
  date = parseDate(date);
  const m = message ? message : 'Must be after ' + date.format(DATE_FORMAT);
  const momentValue = parseDate(value);

  return date.isAfter(momentValue) ? m : null;
};

const validateFutureDateOnFinish = (value: string) => {
  if (value && value.length === 10) {
    return isFutureDate(value);
  }
};

const isFutureDate = (value: string) => {
  const date = moment().startOf('day');
  const momentValue = parseDate(value);

  return date.isSame(momentValue) ? null : isAfter(date, 'Date cannot be in the past')(value);
};

const isAdult = isBefore(moment().startOf('day').subtract(18, 'years'), 'Traveller must be an adult.');
const isChild = isAfter(moment().startOf('day').subtract(25, 'years'),
  'Our family policies are only able to cover children up until the age of 24. If you are looking to purchase insurance for your child that is over 24, they will need a separate individual policy.');
const isGroupChild = isAfter(moment().startOf('day').subtract(18, 'years'),
  'Our group policies are only able to cover children up until the age of 18. If you are looking to purchase insurance for your child that is over 18, they will need a separate individual policy.');
const isDobAFutureDate =
  isBefore(moment().startOf('day').subtract(0, 'day'), 'Traveller\'s age cannot be in the future.');

// tslint:disable:max-line-length
// Regex from http://emailregex.com/
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const isEmail = (value: string): ValidatorReturn => {
  return !emailRegex.test(value) ? 'Invalid Email' : null;
};

const annualLimit = isBefore(moment().startOf('day').subtract(-180, 'day'), 'Your policy cannot start more than 90 days in the future.');
const singleLimit = isBefore(moment().startOf('day').subtract(-365, 'day'), 'Your trip cannot start more than 365 days in the future.');
const minTripDuration = (value) => isAfter(moment(parseDate(value)).startOf('day').subtract(-1, 'day'), 'Your trip must be longer than 1 day.');
// const singleMaxTripDuration = isBefore(moment().startOf('day').subtract(-1, 'day'), 'Your trip must be longer than 1 day.');

const singleMaxTripDuration = (value: string) => {
  const date = moment().startOf('day').subtract(-90, 'day');
  const momentValue = parseDate(value);

  return date.isAfter(momentValue) ? isBefore(moment().startOf('day').subtract(-365, 'day'), 'Your trip cannot end after 365 days from today.') : isBefore(moment().startOf('day').subtract(-455, 'day'), 'Your trip cannot end after 455 days from today.');
};

const phoneRegex = /^[+]?[0-9]+$/;

const isPhone = (value: string): ValidatorReturn => {
  return !phoneRegex.test(value) || (value).length < 11 ? 'Invalid phone number' : null;
};

const postcodeRegex = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})/;

const isPostcode = (value: string): ValidatorReturn => {
  return !postcodeRegex.test(value) ? 'Invalid postcode' : null;
};

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*?()\\\-_=+{};:,<.>])[A-Za-z\d$!@#$%^&*?()\\\-_=+{};:,<.>]{10,}/;

const isValidPassword = (value: string): ValidatorReturn => {
  return !passwordRegex.test(value) ? 'Password must be 10 characters long, with at least one lowercase letter, one uppercase letter, one digit and one special character from the following list: !@#$%^&*?()\-_=+{};:,<.>' : null;
};

const isSame = (expects: string, message = 'This value must be the same as %s') => (value: string): ValidatorReturn => {
  return expects === value ? null : message.replace(/%s/i, expects);
};

const doesNotStartWith = (items: string[], stripSpaces = false, message = 'This value cannot start with %s.') => (value: string): ValidatorReturn => {
  let valid = true;

  items.forEach((item) => {
    let checkAgainst = value;

    if (stripSpaces) {
      checkAgainst = value.replace(' ', '');
    }

    if (checkAgainst.toLocaleLowerCase().startsWith(item.toLocaleLowerCase())) {
      valid = false;
    }
  });

  return valid ? null : message.replace(/%s/i, items.join(', '));
};

const isNotLongerThan = (length: number, message = 'This value cannot be longer than %s characters.') =>
  (value: string): ValidatorReturn => {
    return value.length <= length ? null : message.replace(/%s/i, length.toString());
  };

const isNotShorterThan = (length: number, message = 'This value cannot be shorter than %s characters.') =>
  (value: string): ValidatorReturn => {
    return value.length > length ? null : message.replace(/%s/i, length.toString());
  };

const isLengthBetween = ([min, max]: [number, number],
                         message = 'This value should be between %s and %s characters.') =>
  (value: string): ValidatorReturn => {
    const length = value.length;

    return length >= min && length <= max ? null : message.replace(/%s/i, [min, max].join(', '));
  };

const isLuhnHappy = (message = 'This does not look like a valid card number. This should be the long 16 digit number on the front of your card.') => (value: string): ValidatorReturn => {
  const val = value.replace(/\s/g, '');

  return luhn.validate(val) ? null : message;
};

const expiryDateRegex = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;

const isValidExpiryDate = (value: string): ValidatorReturn => {
  return !expiryDateRegex.test(value) ? 'Expiry date must be in the format MM/YY' : null;
};

const hasEmojis = (value: string): ValidatorReturn => {
  const matches = emojiRegex().exec(value);
  return matches && matches.length > 0 ? 'Please use valid characters only' : null;
};

const SPECIAL_CHARS_REGEX = /[!@#$%?]/;
const DIGIT_REGEX = /[0-9]/;
const UPPER_CASE = /(.*[A-Z].*)/;
const LOWER_CASE = /(.*[a-z].*)/;

const goodPasswordPrinciples = (): PassWordValidatorInput => {
  return [
    {
      label: '10+ characters',
      predicate: (password) => password.length >= 10,
    },
    {
      label: 'with at least one number',
      predicate: (password) => password.match(DIGIT_REGEX) !== null,
    },
    {
      label: 'with at least one uppercase letter',
      predicate: (password) => password.match(UPPER_CASE) !== null,
    },
    {
      label: 'with at least one lowercase letter',
      predicate: (password) => password.match(LOWER_CASE) !== null,
    },
    {
      label: 'with at least one special character',
      predicate: (password) => password.match(SPECIAL_CHARS_REGEX) !== null,
    },
  ];
};

export {
  isRequired,
  isValidDate,
  isFutureDate,
  validateFutureDateOnFinish,
  isAdult,
  isChild,
  isGroupChild,
  isDobAFutureDate,
  annualLimit,
  singleLimit,
  minTripDuration,
  singleMaxTripDuration,
  isAfter,
  isBefore,
  isEmail,
  isPhone,
  isNotFalse,
  isValidPassword,
  isSame,
  doesNotStartWith,
  isPostcode,
  isNotLongerThan,
  isNotShorterThan,
  isValidExpiryDate,
  isLengthBetween,
  isLuhnHappy,
  hasEmojis,
  mustBeFalse,
  goodPasswordPrinciples,
};
