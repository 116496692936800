import * as React from 'react';
import { Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { flow, forEachRight } from 'lodash';
import { II18nMessages } from '../../../../../../services/branding/models';
import {
  IChannel,
  IIndustry,
  IOccupation,
  IPostcodeAddress,
  IProduct,
  ITraveller,
} from '../../../../../../data/api/models';
import { Button, Col, ControlLabel, FormGroup, Row } from 'react-bootstrap';
import FieldFormControl from '../../../../../../components/FieldFormControl';
import PostcodeControl from '../../../../../../components/PostcodeControl';
import TitleField from '../../../../../../components/TitleField';
import { FaTrashAlt } from 'react-icons/fa';
import * as uuid from 'uuid/v4';
import {
  doesNotStartWith,
  hasEmojis,
  isAdult,
  isChild,
  isDobAFutureDate,
  isEmail, isGroupChild,
  isNotLongerThan,
  isPhone,
  isPostcode,
  isRequired,
  isValidDate,
} from '../../../../../../util/validators';
import { IStore } from '../../../../../../redux/IStore';

type TravellerType = 'child' | 'adult';

interface ITravellerTypeConfig {
  min: number;
  max: number;
}

interface IGroupType {
  adult: ITravellerTypeConfig;
  child?: ITravellerTypeConfig;
  travellersMax?: number;
}

interface IGroupTypeMap {
  [type: string]: IGroupType;
}

interface ITripDetailsForm {
  i18n: II18nMessages;
  pristine: boolean;
  fields: any;
  travellers: any[];
  groupType: string;
  tripType: string;
  industries: IIndustry[];
  occupations: IOccupation[];
  channel: IChannel;
  industryValue: string[];
  claims: boolean;
  criminal: boolean;
  change: any;
  sectionTitle?: string;

  renderDefinition(name: string, providedString?: string): any;

  product: IProduct;
  isRenewal: boolean;
}

const groupTypeTravellerMap: IGroupTypeMap = {
  individual: {
    adult: {
      min: 1,
      max: 1,
    },
    child: {
      min: 0,
      max: 0,
    },
  },
  couple: {
    adult: {
      min: 2,
      max: 2,
    },
    child: {
      min: 0,
      max: 0,
    },
  },
  family: {
    adult: {
      min: 2,
      max: 2,
    },
    child: {
      min: 1,
      max: 5,
    },
  },
  family_one_adult: {
    adult: {
      min: 1,
      max: 1,
    },
    child: {
      min: 1,
      max: 5,
    },
  },
  group: {
    adult: {
      min: 1,
      max: 10,
    },
    child: {
      min: 0,
      max: 9,
    },
    travellersMax: 10,
  },
};

const defaultTraveller = {
  id: '',
  title: '',
  firstName: '',
  lastName: '',
  dob: '',
  phone: '',
  email: '',
  type: '',
  screening: null,
  screeningSessionId: null,
  claims: undefined,
  criminal: undefined,
};

class Travellers extends React.Component<ITripDetailsForm> {
  private getAll = (type: TravellerType, fields) => fields.getAll().filter((traveller) => traveller.type === type);
  private count = (type: TravellerType, fields) => this.getAll(type, fields).length;
  private handleAddField = (type: TravellerType, fields) => () => this.addField(type, fields);
  private handleRemoveField = (id, fields) => () => this.removeField(id, fields);

  public state = {
    showAddressField: false,
  };

  private handleTravellerUpdates = (type: TravellerType, config: ITravellerTypeConfig, fields) => {
    let total = this.count(type, fields);

    if (total > config.max) {
      // Remove travellers from the form until the maximum amount of travellers are
      // achieved for the next group type.

      forEachRight(this.getAll(type, fields), (traveller) => {
        if (total <= config.max) {
          return;
        }

        this.removeField(traveller.id, fields);
        total -= 1;
      });
    }

    if (total < config.min) {
      const totalToAdd = config.min - total;

      for (let i = 0; i < totalToAdd; i++) {
        this.addField(type, fields);
      }
    }
  }

  private hideOrShowAddressField = (): void => {
    const travellers: ITraveller[] = this.props.travellers;
    if (travellers.length) {
      if (travellers[0].address && travellers[0].address.postcode) {
        this.setState({ showAddressField: true });
      } else {
        this.setState({ showAddressField: false });
      }
    }
  }

  private handleSelectAddress = (address: IPostcodeAddress = null) => {
    const { change } = this.props;
    const base = 'travellers[0].address';

    change(`${base}.line1`, address?.line1);
    change(`${base}.line2`, address?.line2);
    change(`${base}.town`, address?.town);
    change(`${base}.county`, address?.county);
    change(`${base}.postcode`, address?.postcode);
    this.setState({ showAddressField: true });
  }

  private handleShowAddress = () => {
    this.setState({ showAddressField: true });
  }

  public componentWillReceiveProps(nextProps: ITripDetailsForm) {
    if (this.props.groupType === nextProps.groupType) {
      return;
    }

    const { fields, groupType } = nextProps;
    const config = groupTypeTravellerMap[groupType ? groupType : ''];

    if (config) {
      const { adult, child } = config;

      this.handleTravellerUpdates('adult', adult, fields);
      this.handleTravellerUpdates('child', child, fields);
    }
  }

  public componentDidMount(): void {
    this.hideOrShowAddressField();
  }

  private addField = (type: TravellerType, fields) => {
    fields.push({
      ...defaultTraveller,
      id: uuid(),
      type,
    });
  }

  private getIndex = (id: string, fields) => {
    let travellerIndex = -1;
    const travellers = fields.getAll();

    travellers.forEach((traveller, index) => {
      if (traveller.id === id) {
        travellerIndex = index;
      }
    });

    if (travellerIndex === -1) {
      console.error('Couldn\'t find traveller', travellers, id, travellerIndex);
    }

    return travellerIndex;
  }

  private removeField = (id: string, fields) => {
    const travellerIndex = this.getIndex(id, fields);

    if (travellerIndex === -1) {
      return;
    }

    fields.remove(travellerIndex);
  }

  private renderLeadTravellerFields = (traveller, fieldIndex) => {
    const { i18n, renderDefinition, channel } = this.props;

    const postcodeValidators = [
      isRequired,
      isPostcode,
      hasEmojis,
      isNotLongerThan(8)
    ];

    if (channel.channelType === 'AGG') {
      postcodeValidators.push(doesNotStartWith(
        ['JE', 'GY', 'GX'],
        true,
        'We cannot provide cover for your area.',
      ));
    }

    return (
      <div className="section">
        <Row className="section">
          <Row>
            <Col xs={6} className="full-width-xs">
              <div className="manual-field-label">
                {i18n.quote.tripDetails.traveller.email} {renderDefinition('email_address')}
              </div>
              <FormGroup controlId="email">
                <Col sm={12}>
                  <Field
                    name={`travellers[${fieldIndex}].email`}
                    type="email"
                    autoComplete="new-password"
                    component={FieldFormControl}
                    validate={[isRequired, isEmail]}
                    disabled={fieldIndex === 0 && this.props.isRenewal}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col xs={6} className="full-width-xs">
              <div className="manual-field-label">
                {i18n.quote.tripDetails.traveller.phone}
              </div>
              <FormGroup controlId="phone">
                <Col sm={12}>
                  <Field
                    autoComplete="new-password"
                    name={`travellers[${fieldIndex}].phone`}
                    inputmode="numeric"
                    type="text"
                    component={FieldFormControl}
                    validate={[isRequired, isPhone]}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </Row>
        <Row className="section">
          <FormGroup controlId="postcode" id="leadTravellerAddress">
            <Col componentClass={ControlLabel} xs={6} className="full-width-xs">
              {i18n.quote.tripDetails.traveller.postcode}
            </Col>
            <Col xs={6} className="full-width-xs">
              <Field
                autocomplete="new-password"
                name="travellers[0].address.postcode"
                type="text"
                component={PostcodeControl}
                onSearchComplete={this.handleSearchComplete}
                onSelectAddress={this.handleSelectAddress}
                onManualEntry={this.handleShowAddress}
                validate={postcodeValidators}
              />
            </Col>
          </FormGroup>
        </Row>
        {this.state.showAddressField &&
          <Row className="section no-top-border">
            <FormGroup controlId="address">
              <Col componentClass={ControlLabel} xs={6} className="full-width-xs">
                {i18n.quote.tripDetails.traveller.address}
              </Col>
              <Col xs={6} className="full-width-xs">
                <Field
                  autocomplete="new-password"
                  className="line-top"
                  name="travellers[0].address.line1"
                  placeholder="Line 1"
                  type="text"
                  component={FieldFormControl}
                  validate={[isRequired, hasEmojis]}
                />
                <Field
                  autocomplete="new-password"
                  className="line-middle"
                  name="travellers[0].address.line2"
                  placeholder="Line 2"
                  type="text"
                  component={FieldFormControl}
                  validate={[
                    hasEmojis,
                  ]}
                />
                <Field
                  autocomplete="new-password"
                  className="line-middle"
                  name="travellers[0].address.town"
                  type="text"
                  placeholder="Town"
                  component={FieldFormControl}
                  validate={[
                    isRequired,
                    hasEmojis,
                  ]}
                />
                <Field
                  autocomplete="new-password"
                  className="line-bottom"
                  name="travellers[0].address.county"
                  placeholder="County"
                  type="text"
                  component={FieldFormControl}
                  validate={[
                    hasEmojis,
                  ]}
                />
              </Col>
            </FormGroup>
          </Row>
        }
      </div>
    );
  }

  private handleSearchComplete = (showAddress) => {
    this.setState({ showAddressField: showAddress })
  }

  private renderFields(type: TravellerType) {
    const { fields, i18n, groupType, renderDefinition, channel, isRenewal } = this.props;
    const config = groupTypeTravellerMap[groupType ? groupType : ''];
    const travellerConfig = config[type];
    const isGroup = groupType === 'group';

    return this.getAll(type, fields).map((traveller, index) => {
      const fieldIndex = this.getIndex(traveller.id, fields);
      const disableField = (index === 0 && type === 'adult' && isRenewal);

      return (
        <div className="section-group" key={index}>
          <div>
            <div>
              <div className="section">
                {index === 0 && type === 'adult' && (<h3>{i18n.quote.tripDetails.traveller.lead}</h3>)}
                {(index > 0 || type !== 'adult') && (<h3>{i18n.quote.tripDetails.traveller[type]} {index + 1}</h3>)}
                <div className={'trash-btn' + (channel.channelType === 'AGG' ? ' hidden' : '')}>
                  <Button
                    bsStyle="default"
                    onClick={this.handleRemoveField(traveller.id, fields)}
                    className={index <= (travellerConfig.min - 1) ? 'hidden' : ''}
                  >
                    <FaTrashAlt/>
                  </Button>
                </div>
              </div>
              <div className="section">
                <div className="manual-field-label">
                  {i18n.quote.tripDetails.traveller.name}
                </div>
                <FormGroup controlId="name">
                  <div className="full-name-fields">
                    <Col xs={3} className="full-width-xs">
                      <TitleField
                        type={type === 'adult' ? 'adult' : 'child'}
                        name={`travellers[${fieldIndex}].title`}
                        disabled={disableField}
                        validate={[isRequired]}
                      />
                    </Col>
                    <Col xs={3} className="full-width-xs">
                      <Field
                        name={`travellers[${fieldIndex}].firstName`}
                        type="text"
                        placeholder="First Name"
                        component={FieldFormControl}
                        validate={[isRequired, hasEmojis]}
                        disabled={disableField}
                        autocomplete="new-password"
                      />
                    </Col>
                    <Col xs={6} className="full-width-xs">
                      <Field
                        name={`travellers[${fieldIndex}].lastName`}
                        type="text"
                        placeholder="Last Name"
                        component={FieldFormControl}
                        validate={[isRequired, hasEmojis]}
                        disabled={disableField}
                        autocomplete="new-password"
                      />
                    </Col>
                  </div>
                </FormGroup>
              </div>
              <div className="section">
                <FormGroup controlId="dob">
                  <Col componentClass={ControlLabel} xs={6} className="full-width-xs">
                    {i18n.quote.tripDetails.traveller.dob} {renderDefinition('date_of_birth')}
                  </Col>
                  <Col xs={6} className="full-width-xs">
                    <Field
                      name={`travellers[${fieldIndex}].dob`}
                      type="masked-date"
                      inputmode="numeric"
                      component={FieldFormControl}
                      readOnly={disableField}
                      placeholder="dd/mm/yyyy"
                      className="form-control"
                      autocomplete="new-password"
                      validate={type === 'adult' ? [
                        isRequired,
                        isValidDate,
                        isAdult,
                      ] : [
                        isRequired,
                        isValidDate,
                        isDobAFutureDate,
                        isGroup ? isGroupChild : isChild,
                      ]}
                      disabled={disableField}
                    />
                  </Col>
                </FormGroup>
              </div>
              {index === 0 && type === 'adult' && this.renderLeadTravellerFields(traveller, fieldIndex)}
            </div>
          </div>
        </div>
      );
    });
  }

  public componentWillMount() {
    const { fields, groupType } = this.props;
    const fieldLength = fields.length;

    if (fieldLength === 0) {
      this.addField('adult', fields);
    }

    const config = groupTypeTravellerMap[groupType ? groupType : ''];

    if (config) {
      const { adult, child } = config;

      this.handleTravellerUpdates('adult', adult, fields);
      this.handleTravellerUpdates('child', child, fields);
    }
  }

  private handleAdultsAddButton = (): string => {
    const { fields, groupType, travellers, channel } = this.props;
    const config = groupTypeTravellerMap[groupType ? groupType : ''];

    if (groupType === 'group') {
      return travellers.length >= config.travellersMax || (channel.channelType === 'AGG') ? 'hidden' : 'margin-bottom'
    }

    return this.count('adult', fields) >= config.adult.max ? 'hidden' : 'margin-bottom'
  }

  private handleChildAddButton = (): string => {
    const { fields, groupType, travellers, channel } = this.props;
    const config = groupTypeTravellerMap[groupType ? groupType : ''];

    if (groupType === 'group') {
      return travellers.length >= config.travellersMax || (channel.channelType === 'AGG') ? 'hidden' : ''
    }

    return this.count('child', fields) >= config.child.max || (channel.channelType === 'AGG') ? 'hidden' : ''
  }

  public render() {
    const { i18n, fields, groupType, sectionTitle, renderDefinition } = this.props;
    const config = groupTypeTravellerMap[groupType ? groupType : ''];

    return (
      <div>
        <div className="section-title">
          <h2>{sectionTitle ? sectionTitle : 'About who is travelling'}</h2>
        </div>

        <div className="">
          <div className="">
            <div className="margin-bottom">
              {this.renderFields('adult')}
            </div>
            <div style={{ marginBottom: '15px' }}>
              <Button
                bsStyle="primary"
                onClick={this.handleAddField('adult', fields)}
                className={this.handleAdultsAddButton()}
              >
                {i18n.quote.button.adult}
              </Button>
            </div>
          </div>
          <div className={config.child.max === 0 ? 'hidden' : ''}>
            <div className="margin-bottom">
              {this.renderFields('child')}
            </div>
            <div style={{ marginBottom: '15px' }}>
              <Button
                bsStyle="primary"
                onClick={this.handleAddField('child', fields)}
                className={this.handleChildAddButton()}
              >
                {i18n.quote.button.child}
              </Button>
              {groupType === 'group' && renderDefinition('Children', 'Children or dependants under 25 in full-time education')}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let selector;

export default flow([
  (component) => {
    selector = formValueSelector('quote');

    return component;
  },
  connect(
    (state: IStore) => {
      const groupType = selector(state, 'groupType');
      const tripType = selector(state, 'tripType');
      const travellers = selector(state, 'travellers');
      const channel = state.branding.channel;

      return {
        groupType,
        tripType,
        travellers,
        channel,
      };
    },
  ),
])(Travellers);
