import * as React from 'react';
import { flow, forEach, get, includes, keys, startsWith } from 'lodash';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { change, formValueSelector, getFormSyncErrors, reduxForm, submit, } from 'redux-form';
import { isRequired } from '../../../../util/validators';
import { IBenefit } from '../../../../data/api/models';
import { connect } from 'react-redux';
import { IStore } from '../../../../redux/IStore';
import QuoteSummary from '../QuoteSummary';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { actions as quoteActions, actions } from '../../../../services/quote/reducer';
import { FaSpinner } from 'react-icons/fa';
import { DynamicTextWithAdd } from '../AdditionalCover/components/QuestionOptions/DynamicTextWithAdd';
import ConsentAndIntent from '../../../../components/ConsentAndIntent';
import { renewalTypeSelectionEnum } from './components/YourRenewal';
import { CommonPayment, validate } from './common';
import { shouldIgnoreCardPermissions } from '../../../../helpers/Quote';
import MedicalImportantSection from '../QuoteSummary/MedicalImportantSection';

class PaymentForm extends CommonPayment {

  private recalculatePrice = () => {
    const { actions } = this.props;

    actions.change('quote', 'preventPatch', true);

    setTimeout((() => {
      actions.quote.calculate(null, true);
    }), 300);
  }

  private renderQuestionOption = (option, key, exclude) => {
    const { quote, channel, visibility, product } = this.props;
    const quotes = quote.quotes;
    const coverLevel = keys(quotes)[0];
    const tripType = keys(quotes[coverLevel])[0];
    const quotedCover = quotes[coverLevel][tripType];

    // TODO likely to include gadget and golf options all tiers so unsure if this will be relevant
    const boltOns = quotedCover.information.boltOns;
    const currency = quotedCover.currency;
    const boltOn = boltOns ? boltOns[key] : null;
    const boltOnTotal = boltOn ? boltOn.total : 0;

    const channelType = channel.channelType;
    const aggregatorChannel = channelType === 'AGG';
    const isAggregatorCruiseCover = key === 'aggregator_cruise_cover';
    const hasAggregatorCruiseCoverBenefit = includes(keys(boltOns), 'aggregator_cruise_cover');
    const isCruiseCover = key === 'cruise_cover';
    const isGolfCover = key === 'golf_cover';
    const benefits: IBenefit[] = get(quotedCover, 'information.allBenefits', []);
    const schemeCoverLevel = get(quotedCover, 'scheme.coverLevel');

    // some of the options/bolt ons are shown on the trip details page so don't need to be shown here
    if (exclude.includes(key)) {
      return;
    }

    let bodyText = '';
    let dynamicText = '';
    if (isGolfCover) {
      bodyText = product.metadata.definitions[`${key}_alt_title`];
      dynamicText = product.metadata.definitions[`${key}_alt_dynamic`];
    } else {
      bodyText = product.metadata.definitions[`${key}_alt_${coverLevel}`];
      dynamicText = product.metadata.definitions[`${key}_alt_dynamic`];
    }

    if (boltOnTotal === 0) {
      return null;
    }

    if (!aggregatorChannel && isAggregatorCruiseCover) {
      return null;
    }

    if (
      aggregatorChannel
      && (
        (hasAggregatorCruiseCoverBenefit && isCruiseCover)
        || (!hasAggregatorCruiseCoverBenefit && isAggregatorCruiseCover)
      )
    ) {
      return null;
    }

    if (!quotedCover.information) {
      return null;
    }

    // When no is clicked set the add button to false so it is unselected and recalculate the price
    const unselectAdd = () => {
      const { options } = this.props;
      if (options[key]) {
        this.props.change(`options.${key}`, null);
        this.recalculatePrice();
      }
    };

    return (
      <DynamicTextWithAdd title={option.question}
                          dynamicText={dynamicText} bodyText={bodyText} name={`options.${key}`}
                          key={key} onOptionClick={this.recalculatePrice}
                          visibilityOfDynamicText={visibility && visibility.options[key]}
                          disabledCase={quote.isCalculating}
                          optionKeyName={key}
                          props={this.props}
                          currency={currency} boltOnTotal={boltOnTotal}
                          visibilityOfAddButton={visibility && visibility.options[key]}
                          benefits={benefits}
                          isMta={true}
                          coverLevel={schemeCoverLevel}
                          unselectAdd={unselectAdd}/>
    );

  }

  public render() {
    const {
      product,
      submitting,
      permissions,
      i18n,
      change,
      handleSubmit,
      valid,
      dispatch,
      reuseCustomerAddress,
      submitFailed,
      quote,
      password,
      passwordConfirmation,
      errorMessage,
      channel,
      premium,
      quoteReference,
      travellers,
      renderDefinition,
      quoteType,
      renewalStatus,
      medicalsDeclared
    } = this.props;

    const leadTraveller = quote.application.travellers[0];
    const customer = quote.customer;
    const hasMedical = this.hasTravellerMedical()

    const hasCustomer = !!customer;
    const isIOM = leadTraveller.address && startsWith(leadTraveller.address.postcode, 'IM');

    const disableSubmit = (customer.status === 'new' && password !== passwordConfirmation) || isIOM;

    const formHasError = isRequired && submitFailed && !valid;
    const isAggregator = channel.channelType === 'AGG';

    const referrals = get(premium, 'referrals', []);
    const printOptionsEnabled = quote.application.quoteType !== 'renewal';
    const shouldShowSection = quote.application.tripType === 'annual' && quoteType !== 'renewal';
    const ignorePermissions = shouldIgnoreCardPermissions();

    let fraudMessage = '';
    if (errorMessage && errorMessage.toLowerCase().includes('fraud')) {
      fraudMessage = 'Card Payment was unsuccessful: It appears that you are abroad already. Unfortunately we are unable to accept payments if you are already overseas. If this is not the case please call our customer services team on 0333 234 9913';
    }

    if (!permissions && !ignorePermissions) {
      change('renewalStatus', renewalTypeSelectionEnum.MANUAL)
    }

    if (referrals.length > 0) {
      return (
        <div style={{ textAlign: 'center' }}>
          <h1>Sorry, something went wrong</h1>
          <p>
            Unfortunately there has been a problem with your quote , please see
            our <a className="pointer" href={'https://www.admiral.com/contact-us/travel'} target={'_blank'}>Contact
            Us</a> page for
            details on how to get in touch.
          </p>
        </div>
      );
    }

    return (
      <Form horizontal={true} onSubmit={handleSubmit} autoComplete="off">
        {(formHasError || errorMessage) && (
          <div className="error-block-container" id="form-errors">
            <h1>Whoops!</h1>
            {formHasError && (<p>Missing something? Please check the areas marked in orange before you continue.</p>)}
            {errorMessage && (
              <p>{fraudMessage ? fraudMessage : (errorMessage !== 'Payment Error' ? errorMessage : '')}
              </p>
            )}
            {errorMessage && errorMessage === 'Payment Error' && (
              <Row>
                <Col>
                  <p>We have been unable to process your payment request.</p>
                  <p>No Payment has been taken</p>
                  <p>No cover has been set up</p>
                  <p>Your details have been saved – quote Ref {quoteReference}</p>
                  <p>Please try again or <a className="pointer" href={'https://www.admiral.com/contact-us/travel'}
                                            target={'_blank'}>contact us</a> so we can help you further.
                  </p>
                </Col>
              </Row>

            )}
          </div>
        )}
        {isIOM && (
          <div className="error-block-container">
            <p>
              {`If you would like to continue a quote or purchase, please contact our call-centre on ${channel.phone}.`}
            </p>
          </div>
        )}

        <div className="steps-container">

          <QuoteSummary
            channel={channel}
            isAggregator={isAggregator}
            product={product}
            quote={quote}
            renderDefinition={renderDefinition}
            quoteType={quoteType}
            permissions={permissions}
            change={change}
            dispatch={dispatch}
          />

          <MedicalImportantSection
            hasTravellerDeclaredCondition={hasMedical}
            {...this.props}
          />

          {this.renderYourCover()}

          <div className={'duplicate-warning'}>Please make sure you don’t already have cover in place.</div>

          <div>
            <div className="section-title">
              <h2>Terms and Conditions</h2>
            </div>
            {this.renderCompliance()}
            {printOptionsEnabled ? this.renderPrintOptions() : ''}
          </div>

          <div className="btn-bar btn-bar--auto-height">
            <ConsentAndIntent
              permissions={permissions}
              leadTraveller={leadTraveller}
              ignorePermissions={ignorePermissions}
              i18n={i18n}
              change={change}
              channel={channel}
              dispatch={dispatch}
              reuseCustomerAddress={reuseCustomerAddress}
              isMta={false}
              travellers={travellers}
              showRenewal={shouldShowSection}
              renderDefinition={renderDefinition}
              renewalStatus={renewalStatus}
            />
            {this.renderTrackingItems()}
            <div className="clearfix"/>
            <div style={{display: medicalsDeclared === false ? 'none' : ''}}>
              <Row style={{marginTop: '40px'}}>
                <Col xs={8} className={'full-width-xs'}>
                  {this.renderPremium()}
                </Col>
                <Col xs={4} className={'full-width-xs'}>
                  <Button
                    bsStyle="primary"
                    type="submit"
                    bsSize="lg"
                    className="pull-right purchase-button"
                    onClick={this.checkForErrors}
                    disabled={
                      submitting ||
                      (submitFailed && !valid) ||
                      disableSubmit ||
                      !hasCustomer ||
                      quote.waitForPurchaseToComplete
                    }
                  >
                    {quote.waitForPurchaseToComplete && (
                      <FaSpinner className="fa-spin"/>
                    )} PAY NOW
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

let selector;

export default flow([
  reduxForm({
    form: 'quote',
    validate,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  (component) => {
    selector = formValueSelector('quote');

    return component;
  },
  connect(
    (state: IStore) => {
      const channel = state.branding.channel;
      const quotes = state.quote.quotes;
      const errorMessage = state.quote.errorMessage;
      const synchronousError = getFormSyncErrors('quote')(state);
      const schemeId = selector(state, 'schemeId');
      const password = selector(state, 'password');
      const passwordConfirmation = selector(state, 'passwordConfirmation');
      const policyDeclaration = selector(state, 'policyDeclaration');
      const paymentInfo = selector(state, 'payment');
      const startDate = selector(state, 'startDate');
      const endDate = selector(state, 'endDate');
      const post = selector(state, 'communications.post');
      const fraudCheck = state.quote.application.fraudCheck;
      const options = selector(state, 'options');
      const visibility = selector(state, 'visibility');
      const payment = selector(state, 'payment');
      const permissions = selector(state, 'permissions');
      const applicationId = selector(state, 'id');
      const quoteReference = selector(state, 'quoteReference');
      const reuseCustomerAddress = selector(state, 'reuseCustomerAddress');
      const travellers = selector(state, 'travellers');
      const quoteType = selector(state, 'quoteType');
      const renewalStatus = selector(state, 'renewalStatus');
      const medicalsDeclared = selector(state, 'medicalsDeclared');

      let premium = null;

      forEach(quotes, (tripTypes, level) => {
        forEach(tripTypes, (quotedPremium) => {
          if (quotedPremium.scheme.id === schemeId) {
            premium = quotedPremium;
          }
        });
      });

      return {
        schemeId,
        premium,
        channel,
        policyDeclaration,
        password,
        passwordConfirmation,
        errorMessage,
        paymentInfo,
        startDate,
        endDate,
        post,
        fraudCheck,
        options,
        visibility,
        synchronousError,
        payment,
        permissions,
        applicationId,
        quoteReference,
        reuseCustomerAddress,
        travellers,
        quoteType,
        renewalStatus,
        medicalsDeclared
      };
    },
    (dispatch) => ({
      actions: {
        dispatch,
        submit: bindActionCreators(submit, dispatch),
        calculate: bindActionCreators(actions.calculate, dispatch),
        push: bindActionCreators(push, dispatch),
        quote: bindActionCreators({ ...quoteActions }, dispatch),
        change: bindActionCreators(change, dispatch),
      },
    }),
  ),
])(PaymentForm);
