import { url } from './util';
import axios from 'axios';

export enum Endpoints {
  CHANNEL = 'public/channels/{key}',
  CHANNELS = 'public/channels',
  CUSTOMER = 'customer',
  FORGOTTEN_PASSWORD = 'customer/password/remind',
  PASSWORD_RESET = 'customer/password/reset',
  CONTENT_LIST = 'public/contents',
  PUBLIC_LIST = 'public/list',
  PUBLIC_ERROR = 'public/error',
  POLICY_APPLICATIONS = 'public/applications',
  POLICY_APPLICATIONS_SAVE = 'public/save/applications',
  POLICY_APPLICATION = 'public/applications/{id}',
  POLICY_APPLICATION_ISSUE = 'public/applications/{id}:issue',
  POLICY_APPLICATION_PAY = 'public/applications/{id}:pay',
  POLICY_DOCUMENTS_POLL = 'public/policy:documents-poll',
  DESTINATIONS = 'public/product-destinations',
  STATE_CITIES = 'public/country-states',
  INDUSTRIES = 'public/product-industries',
  OCCUPATIONS = 'public/product-occupations',
  POSTCODE_LOOKUP = 'public/postcode/{postcode}',
  POSTCODE_GET = 'public/address/{id}',
  TRANSACTION_PAY = 'public/transactions/{id}:pay',
  AGGREGATOR_POLICY_APPLICATION = 'aggregators/{id}',
  CAMPAIGN_CODE = '/public/campaigns/code/{code}',
  TRACKING_LINKS = 'public/tracking-links/{id}',
  TRACKING_LINKS_BY_NAME = 'public/tracking-link/{name}',
  FRAUD_CHECK = 'public/fraud',
  LAPSE_RENEWAL = 'customer/policies/applications/renewal/{id}:lapse',
  UNLAPSE_RENEWAL = 'customer/policies/applications/renewal/{id}:unlapse',
  CUSTOMER_CLAIMS_IFRAME_URL = 'public/claims/customer:url',
  CUSTOMER_CLAIMS = 'public/claims/customer',
  UPDATE_CUSTOMER_PASSWORD = 'public/user/:update-password',
  UPDATE_POLICY_PERSONAL_DETAILS = 'public/policies/{id}:apply-changes',
  CUSTOMER_MTA_CALCULATE_PREMIUMS = 'customers/mta/applications/{id}/premiums/calculate/?calculate-boltons=true',
  CUSTOMER_APPLICATIONS_STORE = 'customers/mta/applications/store',
  CUSTOMER_APPLICATIONS_ISSUE = 'customers/mta/applications/{id}/issue',
  CUSTOMER_POLICY_GET_INFO = 'customers/mta/policy/{id}/get-info',
  GET_CUSTOMER_POLICY_PAYMENT_METHOD = 'customers/mta/payment-method/{id}/',
  GET_POLICY_CANCELLATION_DETAILS = 'customers/mta/policy/{id}/return-premium',
  GET_CUSTOMER_RATEABLE_CLAIMS = 'customers/rateable/claims/',
  GET_CUSTOMER_UPSELL_MARKETING = 'customers/upsell/marketing/',
  POLICY_APPLICATION_ADD_NOTES = 'public/application/{id}/notes',
  CUSTOMER_DOWNLOAD_DYNAMIC_DOCUMENT = 'customer/document/{id}/{resourceType}/{resourceId}',
  CUSTOMER_DOWNLOAD_DOCUMENTS = 'customers/documents/{id}:download',
  MTA_CANCEL_POLICY = 'customers/mta/policy/{id}:cancel',
  MTA_CUSTOMER_DECLINE_EMAIL = 'customers/mta/decline-email',
  CALCULATE_PREMIUMS = 'public/applications/{id}:calculate/?calculate-boltons=true',
  CALCULATE_PREMIUMS_WITH_FILTERS = 'public/applications/{id}:calculate/?calculate-boltons=true{filters}',
  ADD_NEW_CARD = 'customers/portal/{id}/payment-method',
  ASSIGN_CARD_TO_POLICY = 'customers/portal/{id}/payment-method/{customerPaymentMethodId}:assign',
  UPDATE_POLICY = 'customers/portal/update/policy/{id}',
  RENEWAL_PAYMENT_FAILURE = 'customers/renewal/payment-failure/{reference}',
  GET_HOUSEHOLD_OFFER = 'household/quote/{id}',
  RETRIEVE_POLICY = 'retrieve/policy/{encryptedId}',
  RETRIEVE_QUOTE = 'retrieve/quote/fromId/{encryptedId}',
  INIT_QUOTE_FROM_CUSTOMER = 'init/customer/quote/{encryptedId}'
}

export function documentsPoll(documentsPollToken: string) {
  return axios
    .post(
      url(Endpoints.POLICY_DOCUMENTS_POLL),
      { documentsPollToken },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
    .then((response) => {
      return response.data;
    });
}
