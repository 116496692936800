import axios from 'axios';
import { mtaIsLoading } from '../mta/actions';
import { IVeriskState } from './models';
import * as moment from 'moment';
import { get } from 'lodash';
import { IStore } from '../../redux/IStore';
import { getFormValues } from 'redux-form';

export const START_SCREENING = 'app/verisk/START_SCREENING';
export const FIND_CONDITION = 'app/verisk/FIND_CONDITION';
export const START_DECLARATION = 'app/verisk/START_DECLARATION';
export const DELETE_DECLARATION = 'app/verisk/DELETE_DECLARATION';
export const SET_ANSWER = 'app/verisk/SET_ANSWER';
export const FINISH_DECLARATION = 'app/verisk/FINISH_DECLARATION';
export const FINISH_SCREENING = 'app/verisk/FINISH_SCREENING';
export const IS_SUBMITTING = 'app/verisk/IS_SUBMITTING';
export const IS_SEARCHING = 'app/verisk/IS_SEARCHING';
export const IS_ABOUT_TO_SCREEN = 'app/verisk/IS_ABOUT_TO_SCREEN';
export const IS_EDITING = 'app/verisk/IS_EDITING';
export const IS_DELETING = 'app/verisk/verisk';
export const SET_INDEX = 'app/verisk/SET_INDEX';
export const IS_RECALCULATING_SCORE = 'app/verisk/IS_RECALCULATING_SCORE';
export const IS_LOADING = 'app/verisk/IS_LOADING';
export const SET_ERROR = 'app/verisk/SET_ERROR';

const initialState = {
  isSubmitting: false,
  isSearching: false,
  isEditing: false,
  isDeleting: false,
  isRecalculatingScore: 0,
  isLoading: false,
  isAboutToScreen: false,
  index: 0,
  token: '',
  complete: false,
  conditions: [],
  declaredConditions: {},
  declaration: {},
  result: {},
  errors: null,
  hasMisspelledConditions: false,
};

export function reducer(state = initialState, action): IVeriskState {
  switch (action.type) {
    case SET_INDEX:
      return {
        ...initialState,
        index: action.index,
      };
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.status,
      };
    case IS_RECALCULATING_SCORE:
      return {
        ...state,
        isRecalculatingScore: action.status ? state.isRecalculatingScore++ : state.isRecalculatingScore--,
      };
    case IS_LOADING:
      return {
        ...state,
        isLoading: action.status,
      };
    case IS_SEARCHING:
      return {
        ...state,
        isSearching: action.status,
      };
    case IS_EDITING:
      return {
        ...state,
        isEditing: action.status,
      };
    case IS_DELETING:
      return {
        ...state,
        isDeleting: action.status,
      };
    case START_SCREENING:
      return {
        ...state,
        complete: !!action.conditions,
        token: action.token,
        declaredConditions: action.conditions,
      };
    case FIND_CONDITION:
      return {
        ...state,
        conditions: action.data,
        hasMisspelledConditions: action.hasMisspelledConditions,
      };
    case START_DECLARATION:
      return {
        ...state,
        complete: false,
        declaration: action.declaration,
      };
    case SET_ANSWER:
      return {
        ...state,
        declaration: action.declaration,
      };
    case FINISH_DECLARATION:
      return {
        ...state,
        complete: true,
        declaration: {},
        declaredConditions: action.conditions,
      };
    case DELETE_DECLARATION:
      return {
        ...state,
        declaredConditions: action.conditions,
      };
    case FINISH_SCREENING:
      return {
        ...state,
        index: 0,
        result: action.data,
      };
    case SET_ERROR:
      return {
        ...state,
        errors: action.errors
      }
    default:
      return state;
  }
}

export function setIndex(index) {
  return {
    type: SET_INDEX,
    index,
  };
}

export function isSubmitting(status) {
  return {
    type: IS_SUBMITTING,
    status,
  };
}

export function isRecalculatingScore(status) {
  return {
    type: IS_RECALCULATING_SCORE,
    status,
  };
}

export function isLoading(status) {
  return {
    type: IS_LOADING,
    status,
  };
}

export function isEditing(status) {
  return {
    type: IS_EDITING,
    status,
  };
}

export function isDeleting(status) {
  return {
    type: IS_DELETING,
    status,
  };
}

export function isSearching(status) {
  return {
    type: IS_SEARCHING,
    status,
  };
}

export function isAboutToScreen(status) {
  return {
    type: IS_ABOUT_TO_SCREEN,
    isAboutToScreen: true,
  };
}

export function setScreeningErrors(errors = null) {
  return {
    type: SET_ERROR,
    errors,
  };
}

const dateToApi = (date: string): string => {
  if (!date) {
    return '';
  }

  const formattedDate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');

  return formattedDate !== 'Invalid Date' ? formattedDate : '';
};

function startVeriskSession(region, destinations, id, travellerDob) {
  const dob = dateToApi(travellerDob);
  return axios.post(
    '/public/medical/verisk:start',
    {
      meta: {
        region,
        destinations,
        id,
        dob
      },
    },
    { headers: { 'Content-Type': 'application/vnd.api+json' } },
  );
}

function finishVeriskSession(id) {
  return axios.post(
    '/public/medical/verisk:finish',
    {
      meta: {
        id,
      },
    },
    { headers: { 'Content-Type': 'application/vnd.api+json' } },
  );
}

const INCLUDED = '?include=conditions,conditions.questions.options,conditions.questions.selected';

export function startScreening(travellerIndex, screeningId, region, destinations = [], dob, callback) {
  return (dispatch) => {
    dispatch(setIndex(travellerIndex));
    dispatch(isSubmitting(true));

    return startVeriskSession(region, destinations, screeningId, dob)
      .then((sessionResponse) => {
        if (screeningId) {
          return axios
            .get('/public/medical/verisk/' + sessionResponse.data.meta.id + INCLUDED)
            .then((res) => {
              dispatch({
                type: START_SCREENING,
                token: sessionResponse.data.meta.id,
                conditions: res.data,
              });
              dispatch(isSubmitting(false));

              callback(sessionResponse.data.meta.id);

              return true;
            })
            .catch((err) => {
              dispatch(isSubmitting(false));
              console.error(err);
            });
        }

        dispatch({
          type: START_SCREENING,
          token: sessionResponse.data.meta.id,
          conditions: {},
        });
        dispatch(isSubmitting(false));

        callback(sessionResponse.data.meta.id);

        return true;
      })
      .catch((err) => {
        dispatch(isSubmitting(false));
        console.error(err);
      });
  };
}

export function recalculateScore(screeningId, region, destinations = [], callback, createNewStore = false) {
  return (dispatch, getState) => {
    const state: IStore = getState();
    const values = getFormValues('quote')(state);
    dispatch(isRecalculatingScore(true));
    dispatch(isLoading(true));

    return axios.post(
      '/public/medical/verisk:recalculate', {
        meta: {
          region,
          destinations,
          id: screeningId,
          create_new_store: createNewStore,
          channel_type: 'B2C-OQJ',
          quote_reference: values?.quoteReference || null,
          policy_number: null,
        },
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    ).then((res) => {
      if (callback) {
        callback(
          res.data.meta.conditions.length ? res.data : '', // screening payload
          res.data.meta.conditions.length ? screeningId : '', // screening id
        );
      }
      dispatch(isRecalculatingScore(false));
      dispatch(mtaIsLoading(false));
      dispatch(isLoading(false));
    }).catch((err) => {
      dispatch(isRecalculatingScore(false));
      dispatch(mtaIsLoading(false));
      dispatch(isLoading(false));
      console.error(err);
    });
  };
}

export function finishScreening(callback) {
  return (dispatch, getState) => {
    dispatch(isSubmitting(true));
    const state = getState().verisk;

    return finishVeriskSession(state.token).then((res) => {
      dispatch({
        type: FINISH_SCREENING,
        data: res.data,
      });

      callback(
        res.data.meta.conditions.length ? res.data : '',
        res.data.meta.conditions.length ? state.token : '',
      );

      dispatch(isSubmitting(false));
      dispatch(setScreeningErrors());
      return true;
    }).catch((err) => {
      dispatch(isSubmitting(false));
      const warning = get(err.response, 'data.errors[0].detail', '');
      if (warning) {
        dispatch(setScreeningErrors({ warning }));
      }
      console.error(err);
    });
  };
}

export function findCondition(search) {
  return (dispatch, getState) => {
    const state = getState().verisk;

    if (!search.length || search.length < 2) {
      return null;
    }

    dispatch(isSearching(true));

    axios.get('/public/medical/verisk/' + state.token + '/conditions?filter[name]=' + encodeURIComponent(search))
      .then((res) => {
        const options = res.data.data.attributes.conditions.map((condition) => {
          return {
            value: condition.id,
            label: condition.name,
          };
        }).filter(Boolean);

        dispatch(isSearching(false));

        dispatch({
          type: FIND_CONDITION,
          data: options,
          hasMisspelledConditions: res.data.data.attributes.has_misspelled_conditions,
        });
        return true;
      }).catch((err) => {
        dispatch(isSearching(false));
        console.error(err);
      });
  };
}

export function startDeclaration(condition) {
  return (dispatch, getState) => {
    const state = getState().verisk;
    dispatch(isSubmitting(true));

    return axios.post(
      '/public/medical/verisk/' + state.token + '/declaration' + INCLUDED,
      {
        id: condition.value,
        name: condition.label,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    ).then((res) => {
      dispatch({
        type: START_DECLARATION,
        index: 0,
        declaration: res.data,
      });

      dispatch(isSubmitting(false));
      return true;
    }).catch((err) => {
      // const message = _.get(err.response, 'data.errors[0].detail');
      // toastr.error('Error', message);

      dispatch(isSubmitting(false));
      console.error(err);

      throw err;
    });
  };
}

export function editDeclaration(declarationId) {
  return (dispatch, getState) => {
    const state = getState().verisk;
    dispatch(isEditing(declarationId));

    return axios.post(
      '/public/medical/verisk/' + state.token + '/declaration' + INCLUDED,
      {
        id: declarationId,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    ).then((res) => {
      dispatch({
        type: START_DECLARATION,
        index: 0,
        declaration: res.data,
      });

      dispatch(isEditing(false));
      return true;
    }).catch((err) => {
      dispatch(isEditing(false));
      console.error(err);
    });
  };
}

export function finishDeclaration() {
  return (dispatch, getState) => {
    const state = getState().verisk;
    dispatch(isSubmitting(true));

    return axios.patch(
      '/public/medical/verisk/' + state.token + '/declaration' + INCLUDED,
      {
        finish: true,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    ).then((res) => {
      return axios.get('/public/medical/verisk/' + state.token + INCLUDED).then((res) => {
        dispatch({
          type: FINISH_DECLARATION,
          conditions: res.data,
        });
        dispatch(isSubmitting(false));
        return true;
      }).catch((err) => {
        dispatch(isSubmitting(false));
        console.error(err);
      });
    }).catch((err) => {
      dispatch(isSubmitting(false));
      console.error(err);
    });
  };
}

export function deleteDeclaration(groupNumber, name) {
  return (dispatch, getState) => {
    const state = getState().verisk;
    dispatch(isDeleting(groupNumber));

    return axios
      .delete(
        '/public/medical/verisk/' + state.token + '/conditions/' + groupNumber,
        { headers: { 'Content-Type': 'application/vnd.api+json' } },
      )
      .then(() => {
        axios
          .get('/public/medical/verisk/' + state.token + INCLUDED)
          .then((res) => {
            dispatch({
              type: DELETE_DECLARATION,
              conditions: res.data,
            });
            dispatch(isDeleting(false));

            // Resolve
            return res;
          })
          .catch((err) => {
            dispatch(isDeleting(false));
            console.error(err);

            // Reject
            throw err;
          });
      })
      .catch((err) => {
        dispatch(isDeleting(false));
        console.error(err);

        throw err;
      });
  };
}

export function setAnswer(condition, question, answer, sendAnswerAsObject = false) {
  return (dispatch, getState) => {
    const state = getState().verisk;

    dispatch(isSubmitting(true));

    return axios.patch(
      '/public/medical/verisk/' + state.token + '/declaration/conditions/' + condition.attributes.number + '/questions/'
      + question.attributes.number + INCLUDED,
      sendAnswerAsObject ? answer : {
        id: answer.value,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    ).then((res) => {
      dispatch({
        type: SET_ANSWER,
        declaration: res.data,
      });

      dispatch(isSubmitting(false));
      return true;
    }).catch((err) => {
      dispatch(isSubmitting(false));
      console.error(err);
    });
  };
}


export function getAnswerOptions(condition, question, declarationNumber) {
  return (dispatch, getState) => {
    const state = getState().verisk;

    dispatch(isSubmitting(true));

    return axios.post('/public/medical/verisk/' + state.token + '/declaration/' + declarationNumber + '/conditions/' + condition.attributes.number + '/questions/' + question.attributes.number + INCLUDED, {
      question: question?.attributes?.text,
      conditionName: condition?.attributes?.name
    }, { headers: { 'Content-Type': 'application/vnd.api+json' } }).then(res => {
      dispatch({
        type: SET_ANSWER,
        declaration: res.data,
      });
      dispatch(isSubmitting(false));
      return true;
    }).catch(err => {
      dispatch(isSubmitting(false));
      console.error(err);
    });
  };
}

