import {
  GroupType,
  IBaseChannel,
  ICustomer,
  IDestination,
  IDocument,
  IIndustry,
  IOccupation,
  IOptionMap,
  IPolicy,
  IPolicyApplication,
  IPremium,
  IProduct,
  IQuotes,
  IStateCities,
  ITraveller,
  TripType,
} from '../../data/api/models';
import { IBrandingState } from '../branding/models';

export interface ICompleteData {
  policy_id?: string;
  policy_number?: string;
  documents_post?: boolean;
  documents_poll_token?: string;
  documents?: {
    [name: string]: string;
  };
}

export interface IQuoteCalculateOptions {
  coverLevel?: string;
  tripType?: string;
  schemeId?: string
}

export interface IQuoteState {
  waitForPurchaseToComplete: boolean;
  isCalculating: boolean;
  initialised: boolean;
  isSubmitting: boolean;
  destinations: IDestination[];
  stateCities: IStateCities[];
  occupations: IOccupation[];
  industries: IIndustry[];
  application: IPolicyApplication;
  quotes: IQuotes;
  policy: any;
  customer: ICustomer;
  complete?: ICompleteData;
  quoteForEdit?: boolean;
  isRetrievingQuote: boolean;
  errorMessage?: string;
  voucherCodeStatus?: string;
  voucherCodeMessage?: string;
  regexVoucherCode?: boolean;
  voucherCodePreventDisplay?: boolean;
  campaignCode: string;
  channels: IBaseChannel[];
  signpost: ISignpostState;
  selectedPremium: IPremium;
  upSellIncreaseRatio?: {
    admiral?: number;
    gold?: number;
    platinum?: number;
  };
  cprResponse?: any;
}

export interface ISignpostState {
  isSignPosted: boolean;
  isMedical: boolean;
}

export interface IQuoteAction {
  type: string;
  payload?: any;
}

export class QuoteObject implements IPolicyApplication {
  public brokerId: string = undefined;
  public communications: { post: boolean };
  public createAccount: boolean = false;
  public customer: ICustomer = undefined;
  public destinations: string[];
  public documentPack: string = '';
  public documents: IDocument[] = [];
  public emailSend: boolean = false;
  public endDate: string = '';
  public generateDocuments: boolean = false;
  public groupType: GroupType;
  public industries: string[] = [];
  public insured: string = '';
  public occupations: string[] = [];
  public password: string = '';
  public passwordConfirmation: string = '';
  public payOnAccount: boolean = false;
  public policyDeclaration: boolean = false;
  public premiums: IPremium[] = [];
  public preventPatch: boolean = false;
  public quotationDeclaration: boolean = true;
  public quotationTypes: string = '';
  public quotePremiums: IPremium[] = [];
  public quoteType: string;
  public region: string;
  public renewingPolicyNumber: string = '';
  public renewingPolicyId: string = '';
  public residency: string = '';
  public startDate: string = '';
  public status: string = '';
  public steps: string[];
  public travellers: ITraveller[];
  public tripType: TripType;
  public upSellIncreaseRatio: { admiral?: number; gold?: number; platinum?: number } = {};
  public options: IOptionMap;

  constructor(communications, destinations, options, region, steps, travellers, tripType, quoteType, groupType) {
    this.communications = communications;
    this.destinations = destinations;
    this.options = options;
    this.region = region;
    this.steps = steps;
    this.travellers = travellers;
    this.tripType = tripType;
    this.quoteType = quoteType;
    this.groupType = groupType;
  }

}

export interface IQuoteActions {
  submitForm(formValues, calculate?: boolean, fromReduxFormValues?: boolean, patch?: boolean);

  saveQuote(calculate?: boolean, formValues?: any);

  updatePolicyApplication(calculate: boolean, formValues?: any);

  retrieveQuote(quote);

  isRetrievingQuote(isRetrieving)

  retrieveQuoteFromPolicy(policy: IPolicy);

  initFromProduct(product: IProduct, quoteState: IQuoteState, brandingState: IBrandingState);

  initRenewal(application: IPolicyApplication, redirectUrl?: string)

  isSubmitting(submitting: boolean);

  loadDestinations();

  loadStateCities();

  loadOccupations();

  loadIndustries();

  calculate(formValues, fromReduxFormValues?: boolean);

  calculateRenewalQuotes(productId, fromReduxFormValues?: boolean)

  calculateQuote(productId: string, fromReduxFormValues?: boolean, updateValues?: boolean, options?: IQuoteCalculateOptions)

  purchase(application);

  reset();

  hasTerminalCondition();

  complete(data: ICompleteData);

  addStepToWizard(step: string, formOnly?: boolean);

  recalculateScreening(formName?: string, shouldCreateStore?: boolean);

  setPassword(password: string, passwordConfirmation: string);

  updateApplication(values: any);

  setApplication(application: IPolicyApplication);

  setQuotes(quotes: IQuotes);

  setCustomer(customer: ICustomer);

  error(message: string);

  checkVoucherCode(campaignCode);

  dispatchVoucherCode(campaignCode);

  setBrokerId(brokerId: string);

  setCampaignCode(campaignCode: string);

  resetVoucherCode();

  lapseRenewal(id, formValues);

  unlapseRenewal(id);

  pollPolicyDocuments(documentsPollToken: string);

  completeDocuments(data: ICompleteData);

  setSteps(steps?: string[]);

  loadChannels();

  fraudCheck(id);

  setWaitForPurchaseToComplete(wait: boolean);

  setSignpost(isSignposted: ISignpostState);

  setSelectedPremium(premium: IPremium);

  addNotes(text: string, policyApplicationId: string);

  getPolicyInfo(policyId: string);

  updatePolicy(policyId: string, data: any)

  getPaymentFailure(renewalId: string)

  calculateUpsellQuote(formValues: any)

  retrievePolicy(policyId: string)

  retrieveQuoteFromId(quoteId: string)

  initQuoteFromCustomer(customerId: string)
}
