import axios from 'axios';
import { _transformContentResponse } from '../../services/content/models';
import { Endpoints } from './endpoints';
import { IFilter } from '../../util/url';
import { url } from './util';
import {
  IApplicationOptions,
  IBaseChannel,
  IChannel,
  ICustomer,
  IDestination,
  IFraudCheckRequest,
  IIndustry,
  IOccupation,
  IPolicyApplication,
  IPostcodeAddress,
  IPostcodeLookup,
  IPremium,
  IStateCities,
  ITrackingLink,
  TripType,
} from './models';
import {
  application,
  channels,
  customer,
  destinations,
  industries,
  marketingUpsells,
  occupations,
  renewalPremiums,
  stateCities,
  trackingLink,
} from './transformers';
import { cloneDeep, get, head } from 'lodash';
import { IQuoteCalculateOptions } from '../../services/quote/models';

const CancelToken = axios.CancelToken;
let cancel = null;

export function listContents(filters?: IFilter[]) {
  return axios
    .get(url(Endpoints.CONTENT_LIST, { filters }))
    .then((response) => {
      return head(response.data.data.map((res) => _transformContentResponse(res)));
    });
}

export function createPolicyApplication(policyApplication: IPolicyApplication,
                                        channelId: string,
                                        brokerId: string,
                                        options: IApplicationOptions = {}): Promise<IPolicyApplication> {
  const extraParams = {
    calculate: 'false',
  };

  if (options.calculate) {
    extraParams.calculate = 'true';
  }

  return axios
    .post(
      url(
        Endpoints.POLICY_APPLICATIONS,
        {
          params: { id: policyApplication.id, ...extraParams },
          includes: options.calculate ? ['premiums.scheme', 'customer', 'active_customer_payment_method', 'payment_methods'] : ['active_customer_payment_method', 'payment_methods'],
        },
      ),
      application.toJsonApi(policyApplication, channelId, brokerId),
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
    .then((response) => {
      const { data, included } = response.data;

      return application.fromJsonApi(data, included);
    });
}

export function updatePolicyApplication(policyApplication: IPolicyApplication,
                                        options: IApplicationOptions = {}): Promise<IPolicyApplication> {
  if (cancel) {
    cancel();
  }

  const extraParams = {
    calculate: 'false',
  };

  if (options.calculate) {
    extraParams.calculate = 'true';
  }

  const request = options.patch ? axios.patch : axios.put;

  return request(
    url(
      Endpoints.POLICY_APPLICATION,
      {
        params: { id: policyApplication.id, ...extraParams },
        includes: options.calculate ? ['premiums.scheme', 'customer', 'channel_scheme_documents', 'active_customer_payment_method', 'payment_methods'] : [],
      },
    ),
    application.toJsonApi(policyApplication),
    {
      headers: { 'Content-Type': 'application/vnd.api+json' },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    },
  )
    .then((response) => {
      const { data, included } = response.data;

      return application.fromJsonApi(data, included);
    });
}

export function getAggregatorPolicyApplication(id: string): Promise<IPolicyApplication> {
  return axios
    .get(
      url(
        Endpoints.AGGREGATOR_POLICY_APPLICATION,
        {
          params: { id },
          includes: ['premiums.scheme', 'customer', 'channel.products', 'channel_scheme_documents'],
        },
      ),
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
    .then((response) => {
      const { data, included } = response.data;

      return application.fromJsonApi(data, included);
    });
}

export function saveQuote(policyApplication: IPolicyApplication,
                          channelId: string,
                          brokerId: string,
                          options: IApplicationOptions = {}): Promise<IPolicyApplication> {
  const extraParams = {
    calculate: 'false',
  };

  if (options.calculate) {
    extraParams.calculate = 'true';
  }

  return axios
    .post(
      url(
        Endpoints.POLICY_APPLICATIONS_SAVE,
        {
          params: { id: policyApplication.id, ...extraParams },
          includes: options.calculate ? ['premiums.scheme', 'customer'] : [],
        },
      ),
      application.toJsonApi(policyApplication, channelId, brokerId),
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
    .then((response) => {
      const { data, included } = response.data;

      return application.fromJsonApi(data, included);
    });
}

export function fraudCheck(request: IFraudCheckRequest) {
  return axios
    .post(
      url(Endpoints.FRAUD_CHECK),
      { meta: request },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
    .then((response) => {
      return response.data;
    });
}

export function lapseRenewal(policyApplicationId: string, formValues) {
  const valuesCloned = cloneDeep(formValues);
  valuesCloned.reason = JSON.stringify(valuesCloned.reason);

  return axios
    .post(
      url(Endpoints.LAPSE_RENEWAL, { params: { id: policyApplicationId } }),
      { meta: valuesCloned },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
    .then((response) => {
      return response.data;
    });
}

export function unlapseRenewal(policyApplicationId: string) {
  return axios
    .post(
      url(Endpoints.UNLAPSE_RENEWAL, { params: { id: policyApplicationId } }),
      {},
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
    .then((response) => {
      return response.data;
    });
}

export function issuePolicyApplication(policyApplication: IPolicyApplication) {
  const permissionValue = get(policyApplication, 'permissions', false);
  const permissionsToApi = {
    consent: permissionValue,
    intent: permissionValue,
    recurring_payments: permissionValue,
  };
  return axios
    .post(
      url(Endpoints.POLICY_APPLICATION_ISSUE, { params: { id: policyApplication.id } }),
      {
        meta: {
          sms_send: policyApplication.sendSms,
          purchase_declaration: policyApplication.purchaseDeclaration,
          payment_token: policyApplication.paymentToken,
          permissions: permissionsToApi,
          reuse_customer_address: get(policyApplication, 'reuseCustomerAddress', false),
          existing_payment_method_id: get(policyApplication, 'existingPaymentMethodId', null),
          billing: {
            title: get(policyApplication, 'billing.title', null),
            first_name: get(policyApplication, 'billing.firstName', null),
            last_name: get(policyApplication, 'billing.lastName', null),
            address: {
              line1: get(policyApplication, 'billing.address.line1', null),
              line2: get(policyApplication, 'billing.address.line2', null),
              town: get(policyApplication, 'billing.address.town', null),
              county: get(policyApplication, 'billing.address.county', null),
              postcode: get(policyApplication, 'billing.address.postcode', null),
              country: get(policyApplication, 'billing.address.country', null),
            },
          },
        },
        data: {
          relationships: {
            scheme: {
              data: {
                id: policyApplication.schemeId,
              },
            },
          },
        },
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
    .then((response) => {
      return response.data;
    });
}

export function issueMTAPolicyApplication(formValues: any, mtaQuoteId: string, premium: any) {
  const permissionValue = get(formValues, 'permissions', false);
  let permissionsToApi = {
    consent: permissionValue,
    intent: permissionValue,
    recurring_payments: permissionValue,
  };

  if (premium && premium.gross && premium.gross < 0) {
    permissionsToApi = null;
  }

  const data = {
    meta: {
      purchase_channel: 'admiral-b2c',
      sms_send: get(formValues, 'sendSms', false),
      payment_token: formValues.paymentToken,
      permissions: permissionsToApi,
      reuse_customer_address: get(formValues, 'reuseCustomerAddress', false),
      existing_payment_method_id: get(formValues, 'existing_payment_method_id', null),
      billing: {
        title: get(formValues, 'billing.title', null),
        first_name: get(formValues, 'billing.firstName', null),
        last_name: get(formValues, 'billing.lastName', null),
        address: {
          line1: get(formValues, 'billing.address.line1', null),
          line2: get(formValues, 'billing.address.line2', null),
          town: get(formValues, 'billing.address.town', null),
          county: get(formValues, 'billing.address.county', null),
          postcode: get(formValues, 'billing.address.postcode', null),
          country: get(formValues, 'billing.address.country', null),
        },
      },
    },
    data: {
      relationships: {
        scheme: {
          data: {
            id: get(formValues, 'data.attributes.scheme_id'),
          },
        },
      },
    },
  }

  return axios
    .post(
      url(Endpoints.POLICY_APPLICATION_ISSUE, { params: { id: mtaQuoteId } }),
      data,
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
    .then((response) => {
      return response.data;
    });

}

export function getTrackingLink(id: string): Promise<ITrackingLink> {
  return axios
    .get(url(Endpoints.TRACKING_LINKS, { params: { id } }))
    .then((response) => {
      return trackingLink.fromJsonApi(response.data.data, response.data.included);
    });
}

export function getTrackingLinkByName(name: string): Promise<ITrackingLink> {
  return axios
    .get(url(Endpoints.TRACKING_LINKS_BY_NAME, { params: { name } }))
    .then((response) => {
      return trackingLink.fromJsonApi(response.data.data, response.data.included);
    });
}

export function checkCampaignCode(code, chanelId: string) {
  return axios
    .get(
      url(Endpoints.CAMPAIGN_CODE, { params: { code, channel: chanelId } }),
    )
    .then((response) => {
      return response.data;
    });
}

export function listDestinations(): Promise<IDestination[]> {
  // Absurdly high limit to get pass limitations in pagination
  return axios
    .get(url(Endpoints.DESTINATIONS, { limit: 10000 }))
    .then((response) => {
      return destinations.fromJsonApi(response.data.data);
    });
}

export function listStateCities(): Promise<IStateCities[]> {
  return axios
    .get(url(Endpoints.STATE_CITIES, { limit: 10000 }))
    .then((response) => {
      return stateCities.fromJsonApi(response.data.data);
    });
}

export function listChannels(): Promise<IBaseChannel[]> {
  return axios
    .post(url(Endpoints.CHANNELS))
    .then((response) => {
      return channels.fromJsonApiArray(response.data.data);
    });
}

export function listOccupations(): Promise<IOccupation[]> {
  // Absurdly high limit to get pass limitations in pagination
  return axios
    .get(url(Endpoints.OCCUPATIONS, { limit: 10000 }))
    .then((response) => {
      return occupations.fromJsonApi(response.data.data);
    });
}

export function listIndustries(): Promise<IIndustry[]> {
  // Absurdly high limit to get pass limitations in pagination
  return axios
    .get(url(Endpoints.INDUSTRIES, { limit: 10000 }))
    .then((response) => {
      return industries.fromJsonApi(response.data.data);
    });
}

export function getChannel(key: string): Promise<IChannel> {
  return axios
    .post(url(Endpoints.CHANNEL, { params: { key }, includes: ['products'] }))
    .then((response) => {
      return channels.fromJsonApi(response.data.data, response.data.included);
    });
}

export function lookupPostcode(postcode: string): Promise<IPostcodeLookup[]> {
  return axios
    .get(
      url(Endpoints.POSTCODE_LOOKUP, { params: { postcode: encodeURIComponent(postcode) } }),
    )
    .then((response) => {
      return response.data.data.map((lookup) => ({
        id: lookup.id,
        line: lookup.attributes.name,
      }));
    });
}

export function getPostcode(id: string): Promise<IPostcodeAddress> {
  return axios
    .get(
      url(Endpoints.POSTCODE_GET, { params: { id } }),
    )
    .then((response) => {
      return response.data.data.attributes;
    });
}

export function getPaymentUrl(transactionId: string) {
  return axios.defaults.baseURL + '/' + url(Endpoints.TRANSACTION_PAY, {
    params: {
      id: transactionId,
    },
  });
}

export function getCustomer() {
  return axios
    .get(
      url(Endpoints.CUSTOMER, {
        includes: [
          'policies.documents',
          'policies.premiums',
          'policies.premiums.scheme',
          'policies.active_customer_payment_method',
          'policies.policy_customer_payment_method',
          'quotes.documents',
          'quotes.premiums',
          'quotes.premiums.scheme',
          'quotes.brokers',
          'renewals.documents',
          'renewals_lapsed_by_customer.documents',
          'renewals.active_customer_payment_method',
          'renewals.policy_customer_payment_method',
          'payment_methods'
        ],
      }),
    )
    .then((response) => {
      return customer.fromJsonApi(response.data.data, response.data.included);
    });
}

export function getCustomerRateableClaims() {
  return axios
    .get(
      url(Endpoints.GET_CUSTOMER_RATEABLE_CLAIMS)
    )
    .then((response) => {
      return response.data.data
    })
}

export function getCustomerUpsellMarketing() {
  return axios
    .get(
      url(Endpoints.GET_CUSTOMER_UPSELL_MARKETING),
      { headers: { 'Content-Type': 'application/vnd.api+json' } }
    )
    .then((response) => {
      return marketingUpsells.fromJsonApi(response.data.data)
    })
}

export function getCustomerClaimsIframeUrl() {
  return axios
    .post(
      url(Endpoints.CUSTOMER_CLAIMS_IFRAME_URL),
    )
    .then((response) => {
      return customer.customerClaimsIframeUrlFromJsonApi(response);
    });
}

export function getCustomerClaims() {
  return axios
    .post(
      url(Endpoints.CUSTOMER_CLAIMS),
    )
    .then((response) => {
      return customer.customerClaimsFromJsonApi(response);
    });
}

export function getHouseHoldOffer(id: string) {
  return axios
    .get(
      url(Endpoints.GET_HOUSEHOLD_OFFER, { params: { id } }),
    )
    .then((response) => {
      return response.data.meta
    });
}

export function updateCustomer(customerValues: ICustomer) {
  return axios
    .patch(
      url(Endpoints.CUSTOMER, {
        includes: ['policies.documents', 'policies.premiums',
          'quotes.documents', 'quotes.premiums'],
      }),
      customer.toJsonApi(customerValues),
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
    .then((response) => {
      return customer.fromJsonApi(response.data.data, response.data.included);
    });
}

export function updatePolicy(id: string, data: any) {
  return axios
    .patch(
      url(Endpoints.UPDATE_POLICY, { params: { id } }),
      data,
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
    .then((response) => {
      return response;
    })
}

export function passwordReminder(email) {
  return axios
    .post(
      url(Endpoints.FORGOTTEN_PASSWORD),
      { email },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    );
}

export function accountCreator(email) {
  return axios
    .post(
      url(Endpoints.FORGOTTEN_PASSWORD),
      { email },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    );
}

// noinspection TsLint
export function resetPassword(token, { password, passwordConfirmation, email }) {
  return axios
    .post(
      url(Endpoints.PASSWORD_RESET),
      {
        token,
        password,
        password_confirmation: passwordConfirmation,
        email,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    );
}

export function showDestinations(tripType: TripType): boolean {
  return tripType === 'single';
}

export function showRegions(tripType: TripType): boolean {
  return tripType === 'annual' || tripType === 'longstay';
}

export function showTripExtension(tripType: TripType): boolean {
  return tripType === 'annual';
}

export function documentsPoll(documentsPollToken: string) {
  return axios
    .post(
      url(Endpoints.POLICY_DOCUMENTS_POLL),
      { documentsPollToken },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
    .then((response) => {
      return response.data;
    });
}

export function updateUserPassword(currentPassword: string, newPassword: string, newPasswordConfirmation: string) {
  return axios.post(
    url(Endpoints.UPDATE_CUSTOMER_PASSWORD),
    { currentPassword, newPassword, newPasswordConfirmation },
  ).then((response) => {
    return response;
  });
}

export function addNotes(text: string, applicationId: string) {
  return axios.post(url(Endpoints.POLICY_APPLICATION_ADD_NOTES, { params: { id: applicationId } }),
    { data: { attributes: { content: text } } },
    { headers: { 'Content-Type': 'application/vnd.api+json' } })
    .catch((error) => {
      console.error(error);
    });
}

export function calculateRenewalQuotes(policyApplication: IPolicyApplication, productId: string): Promise<IPremium[]> {

  return axios
    .post(
      url(
        Endpoints.CALCULATE_PREMIUMS,
        {
          params: { id: policyApplication.id },
          includes: ['premiums.scheme'],
        },
      ),
      application.toJsonApi(policyApplication, null, null, productId),
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
    .then((response) => {
      const { meta } = response.data;
      return renewalPremiums.fromJsonApi(meta)
    });
}

export function calculateQuote(policyApplication: IPolicyApplication, productId: string, options: IQuoteCalculateOptions): Promise<IPremium[]> {

  const ADMIRAL = 'admiral';
  const GOLD = 'gold';
  const PLATINUM = 'platinum';
  let filters: string = '';

  if (options) {
    switch (options.coverLevel) {
      case ADMIRAL:
        filters += '&filter[cover_level]=admiral';
        break;
      case GOLD:
        filters += '&filter[cover_level]=gold';
        break;
      case PLATINUM:
        filters += '&filter[cover_level]=platinum';
        break;
    }
    if (get(options, 'tripType', '') === 'single') {
      filters += '&filter[scheme_type]=single';
    } else {
      filters += '&filter[scheme_type]=annual';
    }
  }

  return axios
    .post(
      url(
        Endpoints.CALCULATE_PREMIUMS_WITH_FILTERS,
        {
          params: { id: policyApplication.id, filters },
          includes: ['premiums.scheme'],
        },
      ),
      application.toJsonApi(policyApplication, null, null, productId),
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
    .then((response) => {
      const { meta } = response.data;
      return renewalPremiums.fromJsonApi(meta)
    });
}

export function getDocumentFile(documentId: string, resourceType, resourceId) {
  return axios.get(
    url(Endpoints.CUSTOMER_DOWNLOAD_DYNAMIC_DOCUMENT, {
      params: {
        id: documentId,
        resourcetype: resourceType,
        resourceid: resourceId
      }
    }), { responseType: 'blob' }
  ).then((response) => {
    return response
  }).catch((error) => {
    console.error(error);
  });
}

export function getDocumentsFile(payload: any, resourceType: string, resourceId: string) {
  return axios.post(
    url(Endpoints.CUSTOMER_DOWNLOAD_DOCUMENTS,
      {
        params: {
          id: resourceId
        }
      }), { payload, resourceType }, { headers: { 'Content-Type': 'application/vnd.api+json' } },
  ).then((response) => {
    return response
  }).catch((error) => {
    console.log(error)
  })
}

export function retrievePolicy(encryptedId: string) {
  return axios.get(
    url(Endpoints.RETRIEVE_POLICY,
      {
        params: {
          encryptedId
        }
      }), { headers: { 'Content-Type': 'application/vnd.api+json' } }
  ).then((response) => {
    return response.data.data
  }).catch((error) => {
    return error;
  })
}

export function retrieveQuoteFromId(encryptedId: string) {
  return axios.get(
    url(Endpoints.RETRIEVE_QUOTE,
      {
        params: {
          encryptedId
        }
      }), { headers: { 'Content-Type': 'application/vnd.api+json' } }
  ).then((response) => {
    return response.data.data
  }).catch((error) => {
    return error;
  })
}

export function initQuoteFromCustomer(encryptedId: string) {
  return axios.get(
    url(Endpoints.INIT_QUOTE_FROM_CUSTOMER,
      {
        params: {
          encryptedId
        }
      }), { headers: { 'Content-Type': 'application/vnd.api+json' } }
  ).then((response) => {
    return get(response, 'data.meta.data', null);
  }).catch((error) => {
    return error;
  })
}
