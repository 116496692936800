import { IModalAction, IModalActions, IModalState } from './model';

const OPEN_UPSELL_MODAL = 'OPEN_UPSELL_MODAL';
const CLOSE_UPSELL_MODAL = 'CLOSE_UPSELL_MODAL';
const SHOW_DOCUMENT_DOWNLOAD_MODAL = 'SHOW_DOCUMENT_DOWNLOAD_MODAL';
const CLOSE_DOCUMENT_DOWNLOAD_MODAL = 'CLOSE_DOCUMENT_DOWNLOAD_MODAL';

const initialState: IModalState = {
  upsellModal: {
    opened: false,
    data: null,
  },
  documentsDownloadModal: {
    opened: false,
  }
};

export const reducer = (state: IModalState = initialState, action: IModalAction) => {
  switch (action.type) {
    case OPEN_UPSELL_MODAL:
      return {
        ...state,
        upsellModal: {
          ...state.upsellModal,
          opened: true,
          data: action.payload,
        }
      };
    case CLOSE_UPSELL_MODAL:
      return {
        ...state,
        upsellModal: {
          ...state.upsellModal,
          opened: false,
          data: null,
        }
      };
    case SHOW_DOCUMENT_DOWNLOAD_MODAL:
      return {
        ...state,
        documentsDownloadModal: {
          ...state.upsellModal,
          opened: true,
        }
      };
    case CLOSE_DOCUMENT_DOWNLOAD_MODAL:
      return {
        ...state,
        documentsDownloadModal: {
          ...state.upsellModal,
          opened: false,
        }
      };
    default:
      return state;
  }
};

const openUpsellModal = (data): IModalAction => ({ type: OPEN_UPSELL_MODAL, payload: data });
const closeUpsellModal = (): IModalAction => ({ type: CLOSE_UPSELL_MODAL, payload: null });

const openDocumentsDownloadModal = (): IModalAction => ({ type: SHOW_DOCUMENT_DOWNLOAD_MODAL });
const closeDocumentsDownloadModal = (): IModalAction => ({ type: CLOSE_DOCUMENT_DOWNLOAD_MODAL});

export const actions: IModalActions = {
  openUpsellModal,
  closeUpsellModal,
  openDocumentsDownloadModal,
  closeDocumentsDownloadModal,
};
